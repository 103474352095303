// always start at the top of the page on route change

const anchorate =  require('anchorate');

// exports.onRouteUpdate = () => {
//     anchorate.anchorate();
// }

exports.onRouteUpdate = (location) => {
    if (location.hash) {
      setTimeout(() => {
        document.querySelector(`${location.hash}`).scrollIntoView();
      }, 0);
    }
  };

// exports.onRouteUpdate = () => {
//     if (typeof window !== `undefined`) { window.scrollTo(0, 0)}
// }

// exports.shouldUpdateScroll = args => {
//     return false;
// };
