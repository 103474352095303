// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-app-terms-js": () => import("/Users/ryan/Documents/website-livit/src/templates/app-terms.js" /* webpackChunkName: "component---src-templates-app-terms-js" */),
  "component---src-templates-blog-post-js": () => import("/Users/ryan/Documents/website-livit/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-index-js": () => import("/Users/ryan/Documents/website-livit/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/Users/ryan/Documents/website-livit/.cache/data.json")

